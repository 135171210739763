"use strict";

import "regulus-oscar";

$('body').removeClass('loading');

$(function(){
    if($(window).width() < 768) {
        $('.nav .dropdown-toggle a').removeClass('disabled');
    }
});

$(function(){
    var equalize = function(selector) {
        var boxes = $(selector),
            heights = boxes.map(function(){return this.offsetHeight});
        boxes.css('min-height', Math.max.apply(null, heights) + 'px');
    }
    equalize('.product-box');
});